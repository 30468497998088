import React, { useContext, useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { GlobalContext } from "../../../Context";
import moment from "moment";
import ApproveReject from "./ApproveReject";
import Comments from "./Comments";
import { toast } from "react-toastify";
import Loader from "../../../Component/Spinners/Loader";
import { useHistory } from "react-router-dom";
import { ApprovedSvg, InReviewSvg, RejectedSvg } from "./svgUtils";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FeatureSubContext } from "../FeatureContext";
import ApproveRequestModal from "../../../Component/Modals/ApproveRejectModal";
import HandleSerialNumber from "./HandleSerialNumber";
import SerailIdModal from "../../../Component/Modals/SerailIdModal";
import { getRequest } from "../../../API";
import pdfImg from "../../../pdf-svgrepo-com (6).svg";
import { IoEyeOutline } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import { downloadPDF, isPdfFile } from "./RequestUtils";
import { IoIosExpand } from "react-icons/io";

const ViewData = () => {
  const { setIsApprove, setRequestView, requestView } =
    useContext(FeatureSubContext);
  const { user, modalUtils, setOpenMyRequestTab, openMyRequestTab } =
    useContext(GlobalContext);
  const {
    status,
    ticketNo,
    requestCreatedTime,
    track,
    description,
    emailID,
    imageKey,
    assetId,
    history,
    currency,
  } = requestView || {};
  const [imageUrl, setImageUrl] = useState({ loader: false, image: "" });
  const [showHistory, setShowHistory] = useState(true);
  const [openSerialNo, setSerialNoModal] = useState(false);
  const { loader, image } = imageUrl;
  const historyPush = useHistory();

  useEffect(async () => {
    if (imageKey) {
      setImageUrl({ loader: true });
      try {
        const res = await getRequest(`query/getimage?fileName=${imageKey}`);
        const { result } = res.data || {};
        if (result) setImageUrl({ loader: false, image: result });
      } catch (error) {
        setImageUrl({ loader: false, image: "" });
        toast.info("failed to load Image!");
      }
    }
  }, [imageKey]);
  const handleOpenFile = () => {
    const downloadURL = `${image}`;
    window.open(downloadURL, "_blank");
  };
  // console.log("requestView-->>", requestView);
  // console.log("currency-->>", currency);
  const isImage = !isPdfFile(imageKey);
  console.log("isImage-->>", isImage);
  return (
    <main>
      {modalUtils.approveRejectModal ? <ApproveRequestModal /> : null}
      {openSerialNo ? (
        <SerailIdModal
          setSerialNoModal={setSerialNoModal}
          items={requestView}
          setRequestView={setRequestView}
        />
      ) : null}

      <div
        className={`view-request scrollable ${
          modalUtils.approveRejectModal || openSerialNo
            ? "opacity--reduce"
            : null
        }`}
      >
        <span
          className="close-request"
          onClick={() => {
            historyPush.push("/dashboard");
            setOpenMyRequestTab(openMyRequestTab);
          }}
        >
          <IoCloseOutline />
        </span>

        <section className="request-container">
          <div className="first-div">
            <h1>
              Request Details{" "}
              {loader ? (
                <article className="request--loader">
                  <Loader
                    size={24}
                    borderColor="#3263F3"
                    borderTopColor="#FFF"
                  />
                </article>
              ) : null}
            </h1>

            <article className="ticket--view">
              <p className="ticketId">
                <span>Ticket ID: #{ticketNo}</span>
                {assetId !== "Null" ? (
                  <small className="request-asset">System ID: {assetId}</small>
                ) : null}
              </p>
            </article>
            <article className="request-content">
              <p className="heading-request">{requestView.requestTitle}</p>
              {requestView.currency && (
                <p className="heading-request">
                  Amount : {requestView.currency}
                </p>
              )}

              <p>{description || "No description provided!"}</p>

              {requestView.status.includes("approve") ? (
                <HandleSerialNumber items={requestView} />
              ) : null}

              {imageKey && image ? (
                <div className="image--request">
                  {isPdfFile(imageKey) ? (
                    <div className="test">
                      <img
                        src={pdfImg}
                        alt=""
                        width="180"
                        height="160"
                        onClick={handleOpenFile}
                      />
                    </div>
                  ) : (
                    <div className="test">
                      <img
                        src={image}
                        alt=""
                        width="160"
                        height="180"
                        onClick={handleOpenFile}
                      />
                    </div>
                  )}

                  <GoDownload
                    className={`${
                      isImage ? "download--icon-image" : "download--icon-pdf"
                    }`}
                    size={30}
                    onClick={() => downloadPDF(image, requestView.requestTitle)}
                  />
                </div>
              ) : null}
            </article>
            {status &&
            status.includes("pending") &&
            user.data.emailID !== requestView.emailID ? (
              <article className="modified--button">
                <ApproveReject
                  setSerialNoModal={setSerialNoModal}
                  items={requestView}
                  setIsApprove={setIsApprove}
                />
              </article>
            ) : null}
            <article>
              <Comments emailID={emailID} ticketNo={ticketNo} />
            </article>
          </div>
          <div className="second-div">
            <div className="info-div">
              {requestView && (
                <p>
                  Requestor:{" "}
                  <span className="img-icon">
                    {requestView.requester.charAt(0).toUpperCase()}
                  </span>
                  <span>{requestView && requestView.requester}</span>
                </p>
              )}
              <p>
                Request Created on:{" "}
                <span>{moment(requestCreatedTime).format("Do MMMM YYYY")}</span>
              </p>
              <p>
                Status:{" "}
                {status && status.includes("approved") ? (
                  <span>
                    <ApprovedSvg /> Approved
                  </span>
                ) : status && status.includes("rejected") ? (
                  <span>
                    <RejectedSvg /> Rejected
                  </span>
                ) : (
                  <span>
                    <InReviewSvg /> In Review
                  </span>
                )}
              </p>
              <p>
                Assigned to: <span>{track}</span>
              </p>
            </div>
            <div className="history-div">
              {requestView && (
                <p
                  className={showHistory && "active-history"}
                  onClick={() => setShowHistory(!showHistory)}
                >
                  View History
                  <MdKeyboardArrowRight />
                </p>
              )}
              {showHistory &&
                history.map((history, idx) => {
                  const { statusFrom, statusTo, time } = history;
                  return (
                    <div key={idx}>
                      {statusFrom && (
                        <div className="history-view">
                          <small>{statusFrom && statusFrom[0]}</small>
                          <b className="history-status">{statusFrom}.</b>
                          <b className="history-time">
                            {moment(time).fromNow()}
                          </b>
                        </div>
                      )}
                      <hr />
                      {statusTo && (
                        <>
                          <div className="history-view">
                            <small style={{ marginTop: 5 }}>
                              {statusFrom ? statusFrom[0] : statusTo[0]}
                            </small>
                            <b className="history-status">{statusTo}.</b>
                            <b className="history-time">
                              {moment(time).fromNow()}
                            </b>
                          </div>
                          <hr />
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default ViewData;
