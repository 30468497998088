import axios from "axios";

// prod url...
const url = process.env.REACT_APP_API_ENDPOINT;
const categoryUrl = `${url}/categoryItems`;

let getCookie = document.cookie.split(";");
getCookie = getCookie.find((items) => items.includes("u_detials"));
const getFilteredCookie = getCookie && getCookie.replace("u_detials=", "");

export const sendRequest = async (method, endpoint, data) => {
  return await axios({
    method,
    url: `${url}/${endpoint}`,
    data,
    headers: {
      Authorization: getFilteredCookie,
    },
  });
};

export const categoryRequests = async (method, endpoint, data) => {
  return await axios({
    method: method,
    url: `${categoryUrl}/${endpoint}`,
    data,
  });
};

export const getRequest = async (endpoint) => {
  return await axios.request({
    method: "GET",
    url: `${url}/${endpoint}`,
    headers: {
      Authorization: getFilteredCookie,
    },
  });
};

export const imageSender = async (link, data) => {
  return await axios({
    method: "PUT",
    url: link,
    data,
    headers: {
      "Content-Type": `${data.type}`,
    },
  });
};
