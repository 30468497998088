import React, { Fragment } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { SignInRoute, ProtectedRoute } from "./Paths/AuthRoute";
import LandingPage from "./Pages/LandingPage";
import SignIn from "./Pages/Auth/SignIn";
import Footer from "./Component/Footer";
import DashBoard from "./Pages/Manage/Dashboard";
import SignUp from "./Pages/Auth/SignUp";
import Navbar from "./Component/Navigation/Navbar";
import SuperAdminWorkspace from "./Pages/Auth/SuperAdminWorkspace";
import WelcomeAboard from "./Pages/Auth/WelcomeAboard";
import Error from "./Error";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import PrivacyPolicy from "./Pages/LegalInfo/PrivacyPolicy";
import Terms from "./Pages/LegalInfo/Terms";
import Cancellation from "./Pages/LegalInfo/Cancellation";
import UserWorkspace from "./Pages/Auth/userWorkspace";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  AOS.init();

  const { pathname } = useLocation();
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  return (
    <Fragment>
      <Navbar />
      <ToastContainer position="bottom-right" autoClose="2000" limit={1} />
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <Switch>
          <SignInRoute exact path="/" component={LandingPage} />
          <SignInRoute path="/login" component={SignIn} />
          <SignInRoute path="/register" component={SignUp} />
          <SignInRoute
            path="/createworkspace"
            component={SuperAdminWorkspace}
          />
          <SignInRoute path="/userworkspace" component={UserWorkspace} />
          <SignInRoute path="/welcome" component={WelcomeAboard} />
          <ProtectedRoute path="/dashboard" component={DashBoard} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/terms" component={Terms} />
          <Route path="/cancellation" component={Cancellation} />
          <Route path="*" component={Error} />
        </Switch>
      </GoogleOAuthProvider>
      {!pathname.includes("dashboard") ? <Footer /> : null}
    </Fragment>
  );
};

export default App;
